import React from "react";

import { Layout, SEO, BlueOrangeDivider } from "../../../components/structure";
import {
  CallToAction,
  Content,
  Hero,
  IconTile,
  Button,
  StaggeredColumn,
  Grid,
  Column,
} from "../../../components/blocks";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";
import { mq, brand, font } from "../../../styles";
import english from "../../../../content/translations/en-CA/contact.json";
import { Link } from "gatsby";
import ContactUsForm from "../../../components/structure/ContactUsForm";

const ContactPage = ({ location }) => {
  let lang = english;

  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "GettyImages-sb10068010a-003.jpg" }) {
        ...FullHeroBackground
      }
      iconPartnership: file(base: { eq: "handShake.png" }) {
        childImageSharp {
          fixed(width: 60, height: 40, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      iconHelp: file(base: { eq: "headset.png" }) {
        childImageSharp {
          fixed(width: 45, height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      iconMedia: file(base: { eq: "media.png" }) {
        childImageSharp {
          fixed(width: 42, height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      iconInquires: file(base: { eq: "magGlass.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      toronto: file(base: { eq: "toronto.jpg" }) {
        ...LocationBackground
      }
      montreal: file(base: { eq: "montreal.jpg" }) {
        ...LocationBackground
      }
      dubai: file(base: { eq: "dubai.jpg" }) {
        ...LocationBackground
      }
      london: file(base: { eq: "london.jpg" }) {
        ...LocationBackground
      }
      newyork: file(base: { eq: "newyork.jpg" }) {
        ...LocationBackground
      }
      sanfran: file(base: { eq: "sanfran.jpg" }) {
        ...LocationBackground
      }
      singapore: file(base: { eq: "singapore.jpg" }) {
        ...LocationBackground
      }
      telaviv: file(base: { eq: "telaviv.jpg" }) {
        ...LocationBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right.png" }) {
        ...SubmitButton
      }
    }
  `);

  const SupportTileContainer = styled.div`
    ${font.imports.sherika}

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    gap: 45px;
    padding-bottom: 70px;
    flex-basis: 50%;

    @media only screen and ${mq.maxMd} {
      margin: auto;
      align-items: flex-start;
      justify-content: center;
    }

    @media only screen and (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  `;

  const LeftSideContainer = styled.div`
    h2 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 56px;
      color: ${brand.colors.pg_blue};
    }

    p {
      font-family: ${font.family.sherika};
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      margin-bottom: 70px;
    }
  `;

  const LocationContainer = styled.div`
    padding: 40px 0;
    width: 95%;
    max-width: 1400px;
    margin: auto;

    h2 {
      font-size: 56px;
      font-weight: 600;
      color: ${brand.colors.pg_orange};
      text-align: center;
    }

    p {
      font-family: ${font.family.sherika};
      color: ${brand.colors.pg_primary_dark};
      text-align: center;
    }

    .location-tile-con {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px 100px;
      margin: 50px auto;

      @media only screen and (max-width: 1400px) {
        gap: 20px 300px;
        max-width: 800px;
      }
    }
  `;

  const ContactHeader = styled.div`
    display: flex;
    align-items: space-between;
    width: 95%;
    max-width: 1400px;
    margin: auto;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
    }
  `;
  const ContactFormContainer = styled.div`
    flex-basis: 50%;

    .thank-you-message {
      color: ${brand.colors.pg_blue};
      font-size: 50px;
      line-height: 56px;
      font-family: ${font.family.sherika};
      font-weight: 700;
    }

    @media only screen and ${mq.maxMd} {
      width: 80%;
    }
  `;

  return (
    <Layout>
      <SEO
        title={"Contact | Plusgrade"}
        description={"Contactez nos équipes."}
        keywords={"help, assistance, support"}
      />
      <Hero
        title={"Contactez-nous"}
        subtitle={"Communiquez avec nous"}
        copy={"Avez-vous des questions ? Nous sommes là pour aider."}
        noImage
      />

      <Content noMargin>
        <ContactHeader>
          <LeftSideContainer>
            <SupportTileContainer>
              <SupportBlock
                icon={data["iconPartnership"].childImageSharp.fixed}
                title={"Partenariats"}
                blurb={
                  "Contactez notre équipe Partenariats pour découvrir comment vous pouvez augmenter vos revenus grâce à d'excellentes expériences client."
                }
                method={
                  <Link
                    className={"menu-link"}
                    to="/fr/contact/demandez-une-demonstration/"
                  >
                    Demander une démonstration
                  </Link>
                }
              />
              <SupportBlock
                icon={data["iconHelp"].childImageSharp.fixed}
                title={"Aide et Support"}
                blurb={
                  "Pour toute question relative à la facturation ou à l'assistance à la clientèle."
                }
                method={"+1.361.666.3617"}
              />
              <SupportBlock
                icon={data["iconMedia"].childImageSharp.fixed}
                title={"Médias"}
                blurb={""}
                method={
                  "Pour toute demande de presse et de médias, veuillez contacter pr@plusgrade.com."
                }
              />
              <SupportBlock
                icon={data["iconInquires"].childImageSharp.fixed}
                title={"Questions générales"}
                blurb={"Pour tout autre sujet, appelez-nous:"}
                method={"+1.514.437.2185"}
              />
            </SupportTileContainer>
          </LeftSideContainer>
        </ContactHeader>
      </Content>
      <BlueOrangeDivider inverse />
      <Content whiteBG noMargin>
        <LocationContainer>
          <h2>Nos bureaux</h2>
          <p>
            Le siège social de Plusgrade est situé à Montréal, avec des bureaux
            à travers le monde.
          </p>
          <div className="location-tile-con">
            <LocationBlock
              background={data["montreal"].childImageSharp.fixed}
              location="Montréal"
              title="Montréal (siège social)
                "
              addLineOne={"2200 Rue Stanley, Suite 700"}
              addLineTwo={"Montréal, Québec H3A 1R6"}
              addLineThree={"Canada"}
            />
            <LocationBlock
              background={data["toronto"].childImageSharp.fixed}
              location="Toronto"
              title="Toronto"
              addLineOne={"111 Richmond St. W., Suite 700"}
              addLineTwo={"Toronto, ON M5H 2G4"}
              addLineThree={"Canada"}
            />
            <LocationBlock
              background={data["newyork"].childImageSharp.fixed}
              location="New York"
              title="New York"
              addLineOne={"16 West 36th Street, 2nd Floor"}
              addLineTwo={"New York, New York 10018"}
              addLineThree={"United States"}
            />
            <LocationBlock
              background={data["sanfran"].childImageSharp.fixed}
              location="San Francisco"
              title="San Francisco"
              addLineOne={"Canopy Jackson Square"}
              addLineTwo={"595 Pacific Ave., CA 94133"}
              addLineThree={"United States"}
            />
            <LocationBlock
              background={data["london"].childImageSharp.fixed}
              location="London"
              title="London"
              addLineOne={"123 Buckingham Palace Rd."}
              addLineTwo={"Office 02-106, Victoria, London, SW1 9SH"}
              addLineThree={"United Kingdom"}
            />
            <LocationBlock
              background={data["dubai"].childImageSharp.fixed}
              location="Dubai"
              title="Dubai"
              addLineOne={"Unit 407, Loft Offices 1"}
              addLineTwo={"Dubai Media City"}
              addLineThree={"United Arab Emirates"}
            />
            <LocationBlock
              background={data["telaviv"].childImageSharp.fixed}
              location="Tel Aviv"
              title="Tel Aviv"
              addLineOne={"Yitzhak Sadeh St 8 Acro, Tower"}
              addLineTwo={"19th Floor, Tel Aviv-Yafo, 6777519"}
              addLineThree={"Israel"}
            />
            <LocationBlock
              background={data["singapore"].childImageSharp.fixed}
              location="Singapore"
              title="Singapore"
              addLineOne={"109 North Bridge Road, #05-21"}
              addLineTwo={"Singapore 179097"}
              addLineThree={"Singapore"}
            />
          </div>
        </LocationContainer>
      </Content>
    </Layout>
  );
};

export default ContactPage;

const SupportBlock = ({ icon, title, blurb, method }) => {
  const ic = icon ? (
    <GatsbyImage fixed={icon} imgStyle={{ objectFit: "contain" }} />
  ) : (
    <></>
  );
  const SupportTile = styled.div`
    ${font.imports.sherika}

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 40%;

    @media only screen and (max-width: 550px) {
      align-items: center;
    }

    .icon-container {
      height: 62px;
    }
    .tile-title {
      font-family: ${font.family.sherika};
      font-weight: 700;
      font-size: 16px;
      @media only screen and (max-width: 550px) {
        text-align: center;
      }
    }

    .tile-copy {
      font-family: ${font.family.sherika};
      font-weight: 400;
      font-size: 13px;
      @media only screen and (max-width: 550px) {
        text-align: center;
      }
    }

    .tile-contact-method {
      font-family: ${font.family.sherika};
      font-weight: 400;
      font-size: 13px;

      a {
        color: ${brand.colors.pg_blue};
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;
  return (
    <SupportTile>
      <div className="icon-container">{ic}</div>
      <div className="tile-title">{title}</div>
      <div className="tile-copy">{blurb}</div>
      <div className="tile-contact-method">{method}</div>
    </SupportTile>
  );
};

const LocationBlock = ({
  background,
  location,
  title,
  addLineOne,
  addLineTwo,
  addLineThree,
}) => {
  const LocationTile = styled.div`
    ${font.imports.sherika}

    width: 250px;
    height: auto;
    min-height: 250px;

    .image-container {
      position: relative;
      width: 250px;
      height: 250px;
      .loc-bg-image-con {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 250px;
        filter: grayscale(1) contrast(1);

        .gatsby-image-wrapper {
          height: 100% !important;
        }

        &:after {
          content: "";
          position: absolute;
          z-index: 350;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.7231267507002801) 10%,
            rgba(0, 0, 0, 0.5508578431372548) 49%,
            rgba(0, 0, 0, 0) 100%
          );
        }
      }

      .loc-title-con {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 24px;
      }
    }

    .loc-details-con {
      color: ${brand.colors.pg_primary_dark};
      font-size: 13px;
      padding-top: 20px;

      span {
        display: block;
        line-height: 20px;

        &:first-of-type {
          font-weight: 600;
          padding-bottom: 20px;
        }
      }
    }
  `;

  return (
    <LocationTile>
      <div className="image-container">
        <div className="loc-bg-image-con">
          <GatsbyImage fixed={background} />
        </div>
        <div className="loc-title-con">{location}</div>
      </div>

      {title || addLineOne || addLineTwo || addLineThree ? (
        <div className="loc-details-con">
          <span>{title}</span>
          <span>{addLineOne}</span>
          <span>{addLineTwo}</span>
          <span>{addLineThree}</span>
        </div>
      ) : (
        <></>
      )}
    </LocationTile>
  );
};

export const query = graphql`
  fragment LocationBackground on File {
    childImageSharp {
      fixed(width: 250, height: 250, webpQuality: 100) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`;
